import { Injectable,Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpClient,HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router ,ActivatedRoute} from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgxSpinnerService} from 'ngx-spinner'
import { SharedServicesService} from '../shared-services/shared-services.service'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  status: any = true;
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
		public inj: Injector,
		public CommonService: SharedServicesService,
		public loaderInject: Injector,
    private router: Router) 
  {}
  private applyCredentials = (request: HttpRequest<any>, token: string) => {
    if(token){
      return request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + token,
        },
      });
    }
    else{
      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8'
        },
      });
    }
     

  };
  intercept(Request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let auth: any = this.inj.get(SharedServicesService);
    let authReq: any = this.applyCredentials(Request, auth.getToken());
    return <any> next.handle(authReq).pipe(
      map((event: any) => {
        //if (event instanceof HttpResponse) {
          return event;
        //}
      }),
      catchError((error: HttpErrorResponse) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
				if (error.status === 401) {
					window.localStorage.clear();
					this.router.navigateByUrl('/');
					// return throwError(() => new Error(error.message));
          return throwError(error);
				} else {
					return throwError(error);
				}
			})
      )
  }
}
