import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard} from './auth-guard/auth-guard.guard'

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
},
{
  path: 'workflow',
  loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule),
  canActivate: [AuthGuardGuard]
},
{
  path: 'pmo',
  loadChildren: () => import('./pmo/pmo.module').then((m) => m.PmoModule),
  canActivate: [AuthGuardGuard]
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
