<router-outlet></router-outlet>
<ngx-spinner
bdOpacity = 0.9
bdColor = "rgba(51,51,51,0.29)"
size = "medium"
color = "#fff"
type = "ball-beat"
[fullScreen] = "true"
>
<p style="color: black" > Loading </p>
</ngx-spinner>
<!-- <app-toast-msg></app-toast-msg> -->
