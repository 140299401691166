import { Component,HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'product-transfer-fe';
  shouldCheckBeforeUnload: boolean = localStorage.getItem('token')?true:false;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if(this.shouldCheckBeforeUnload){
      $event.preventDefault();
      $event.returnValue = true; 
    }
  }

}
