import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedServicesService {

  constructor() { }
  public getToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    } else {
      return null;
    }
  }
  public getDateFormat(dateValue:any) {
    console.log(dateValue,'dateValue')
    let date:any = new Date(dateValue);
    let year:any = date.getFullYear();
    let month:any = date.getMonth()+1;
    let dt:any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let formattedDate = year+'-' + month + '-'+dt;
    return formattedDate;
  }
  public getMatchingPairs(arr: any[], value: any): any[] {
    return arr.filter(item => {
      for (const key in item) {
          if (item[key] == value) {
            return true;
          }
      }
      return false;
    });
  }
  
}
